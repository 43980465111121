import React, { Component } from "react";


// <Route exact path={`${process.env.PUBLIC_URL}/gmx-audit`} component={PortfolioDetails}/>
// <Route exact path={`${process.env.PUBLIC_URL}/uniswap-audit`} component={PortfolioDetails}/>
// <Route exact path={`${process.env.PUBLIC_URL}/shibainu-audit`} component={PortfolioDetails}/>
// <Route exact path={`${process.env.PUBLIC_URL}/floki-audit`} component={PortfolioDetails}/>
// <Route exact path={`${process.env.PUBLIC_URL}/gamma-audit`} component={PortfolioDetails}/>
// <Route exact path={`${process.env.PUBLIC_URL}/bayc-audit`} component={PortfolioDetails}/>

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <a href="/gmx-audit">
                            <img src="/assets/images/brand/brand-01.png" alt="Logo Images"/>
                        </a>
                    </li>
                    <li>
                        <a href="/uniswap-audit">
                            <img src="/assets/images/brand/brand-02.png" alt="Logo Images"/>
                        </a>
                    </li>
                    <li>
                        <a href="/shibainu-audit">
                            <img src="/assets/images/brand/brand-03.png" alt="Logo Images"/>
                        </a>
                    </li>
                    <li>
                        <a href="/floki-audit">
                            <img src="/assets/images/brand/brand-04.png" alt="Logo Images"/>
                        </a>
                    </li>
                    <li>
                        <a href="/gamma-audit">
                            <img src="/assets/images/brand/brand-05.png" alt="Logo Images"/>
                        </a>
                    </li>
                    <li>
                        <a href="/bayc-audit">
                            <img src="/assets/images/brand/brand-06.png" alt="Logo Images"/>
                        </a>
                    </li>
                    <li>
                        <a href="/bayc-audit">
                            <img src="/assets/images/brand/brand-07.webp" alt="Logo Images"/>
                        </a>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;