import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About',
        description = 'We offer a range of services, including security audits, penetration testing, vulnerability assessments, and security consulting. Whether you are a blockchain project, a decentralized application, or a cryptocurrency exchange, we have the expertise to help you secure your assets and data.';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>Our team consists of experienced professionals with backgrounds in cybersecurity, cryptography, and blockchain technology. We are constantly researching and staying up-to-date on the latest threats and vulnerabilities in the web3 space to ensure that our clients are protected.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">What we stand for</h3>
                                                <p>At our core, we believe that security is not a luxury, but a necessity. As the world becomes increasingly interconnected and data-driven, the risks associated with online activities continue to grow. That's why we are committed to providing our clients with the highest level of security possible.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;