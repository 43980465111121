import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import {FaTwitter ,FaInstagram ,FaTelegram, FaLinkedinIn, FaBookOpen, FaDiscord} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

const SocialShare = [
    {Social: <FaBookOpen /> , link: 'https://www.gamma.xyz/'},
    {Social: <FaBookOpen /> , link: 'https://www.visor.finance/'},
    {Social: <FaDiscord /> , link: 'https://discord.com/invite/CavDkyBZ'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/gammastrategies'},
]
 
class PortfolioDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Visor Finance / Gamma</h2>
                                    <p>Tooling for active liquidity management</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>Is Gamma safe?</h2>
                                        <p className="subtitle">
                                        Tooling for active liquidity management on concentrated liquidity AMM's such as Uniswap v3. Visor developed a series of smart contracts and management infrastructure used by managers and market makers. Visor has merged with Gamma Strategies.
                                        </p>
                                        <p>
                                        Our team of cybersecurity experts has conducted an in-depth analysis of Visor.finance's "HyperVisor" smart contracts and has discovered major vulnerabilities that could result in the loss of funds. 
                                        These vulnerabilities pose a significant risk to the security and stability of the system, and they have been addressed immediately to prevent potential financial losses or other harmful outcomes.
                                        </p>

                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Project</span>
                                                <h4>GAMMA / VISOR</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Types</span>
                                                <h4>DeFi</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Audit Link</span>
                                                <h4> <a href="/assets/audits/Gamma Audit.pdf" download>Download</a></h4>
                                            </div>
                                        </div>

                                        <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val , i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        {/* <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div> */}
                                        
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/visor.png" alt="Portfolio Images"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default PortfolioDetails;
