import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import {Link} from "react-router-dom";
import BrandTwo from "../../../elements/BrandTwo";


const PortfolioList = [
    {
        image: 'image-1',
        category: 'Token',
        title: 'SafeMoon'
    },
    {
        image: 'image-2',
        category: 'DeFi',
        title: 'GMX - Perpetuals Futures'
    },
    {
        image: 'image-3',
        category: 'DeFi',
        title: ' Getting tickets to the big show'
    },
    {
        image: 'image-4',
        category: 'DeFi',
        title: ' Getting tickets to the big show'
    },
    {
        image: 'image-5',
        category: 'DeFi',
        title: ' Getting tickets to the big show'
    }
]

class Portfolio extends Component{
    render(){
        let title = 'Our Works',
        description = 'A list of projects our team of professionals have audited. Click on them to open.';
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </div>
                                <div className="service-btn">
                                    <a className="btn-transparent rn-btn-dark" href="mailto:contact@dwebox.com"><span className="text">Contact Dwebox</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        {/* <Slider {...portfolioSlick2}>
                                {PortfolioList.map((value , index) => (
                                    <div className="portfolio" key={index}>
                                        <div className="thumbnail-inner">
                                            <div className={`thumbnail ${value.image}`}></div>
                                            <div className={`bg-blr-image ${value.image}`}></div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <p>{value.category}</p>
                                                <h4 className="title"><a href="/portfolio-details">{value.title}</a></h4>
                                                <div className="portfolio-button">
                                                    <a className="rn-btn" href="/portfolio-details">Case Study</a>
                                                </div>
                                            </div>
                                        </div>
                                        <Link className="link-overlay" to="/portfolio-details"></Link>
                                    </div>
                                ))}
                            </Slider> */}
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;