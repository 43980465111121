const BlogContent = [
    {
        images: '01',
        title: 'Importance of audits in crypto',
        category: 'Tips',
        link:'/importance-of-crypto-audits'
    },
    {
        images: '02',
        title: 'Introduction to crypto honeypots',
        link:'/what-is-a-crypto-honeypot',
        category: 'Prevention'
    },

    {
        images: '03',
        title: 'What are flashloans?',
        link:'/defi-flashloans',
        category: 'DeFi'
    },
    // {
    //     images: '04',
    //     title: ' Getting tickets to the big show',
    //     category: 'Development'
    // },
    // {
    //     images: '01',
    //     title: 'A big ticket gone to be an interesting ',
    //     category: 'Management'
    // },
    // {
    //     images: '02',
    //     title: 'The Home of the Future Could Bebes',
    //     category: 'Design'
    // },
]

export default BlogContent;