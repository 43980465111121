import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import {FaTwitter ,FaInstagram ,FaTelegram, FaLinkedinIn, FaBookOpen} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

const SocialShare = [
    {Social: <FaBookOpen /> , link: 'https://gmx.io'},
    {Social: <FaTelegram /> , link: 'https://t.me/GMX_IO'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/GMX_IO'},
]

class PortfolioDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">BAYC and APE Coin</h2>
                                    <p>NFT collection and platform coin</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>Is BAYC safe?</h2>
                                        <p className="subtitle">
                                        A limited NFT collection where the token itself doubles as membership to a swamp club for apes.
                                        </p>
                                        <p>
                                        After conducting a thorough analysis of the APE Coin and BAYC NFT smart contracts, our team of cybersecurity experts has identified an area for improvement within the 
                                        BAYC NFT smart contract. Specifically, we recommend incorporating the "SafeMath" library to ensure safer and more secure calculations, 
                                        which can help prevent potential vulnerabilities related to integer overflows or underflows. We believe that implementing this 
                                        improvement will enhance the overall security and reliability of the NFT smart contract, and reduce the risk of potential financial losses or other negative outcomes.
                                        </p>

                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Project</span>
                                                <h4>BAYC</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Types</span>
                                                <h4>NFT </h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Audit Link</span>
                                                <h4> <a href="/assets/audits/BoredApe Yacht Club Audit.pdf" download>Download</a></h4>
                                            </div>
                                            <div className="port-view">
                                                <span>Audit Link</span>
                                                <h4> <a href="/assets/audits/Apecoin Audit.pdf" download>Download 2</a></h4>
                                            </div>
                                        </div>

                                        <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val , i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        {/* <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div> */}
                                        
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/bayc.png" alt="Portfolio Images"/>
                                        </div>
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/apecoin.png" alt="Portfolio Images"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default PortfolioDetails;
