import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class ServiceDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                {/* <PageHelmet pageTitle='Service Details' /> */}
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Web3 Audits</h2>
                                    <p>From the best team in the space that earned multiple bug bounties.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-01.png" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                        Dwebox provides a range of services related to cybersecurity in the web3 and blockchain space. 
                                                    </p>
                                                    <p>
                                                        Whether you are looking for a simple contract audit, a complex protocol to prevent from exploits or simply having your frontend dapp 
                                                        secured, we are here to help.
                                                    </p>
                                                    <h4 className="title">Our Process</h4>
                                                    <ul className="liststyle">
                                                        <li>We learn your requirements</li>
                                                        <li>We evaluate your protocol</li>
                                                        <li>A free quote is provided to you</li>
                                                        <li>Our team starts working on auditing the protocol</li>
                                                        <li>A final PDF report is posted on our website</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                    In today's world, the number of hackers has increased. We are seeing breaches worth tens and hundreds of millions of dollars. Hackers have gotten smarter and that's why you need a top team 
                                                    of security experts. 
                                                    </p>
                                                    <h4 className="title">Service Coverage</h4>
                                                    <ul className="liststyle">
                                                        <li>Security Strategy</li>
                                                        <li>Smart Contract audit</li>
                                                        <li>DAPP audit</li>
                                                        <li>Safe KYC</li>
                                                        <li>Final Report with score</li>

                                                    </ul>
                                                </div>
                                                <hr />
                                                <p>Contact us with as many details to receive an estimate:
                                                </p>
                                                <div className="header-btn">
                                                    <a className="rn-btn" href="mailto:contact@dwebox.com">
                                                        <span>get a free quote</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-02.png" alt="Service Images"/>
                                                    {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} /> */}
                                                    {/* <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;